<template>
    <div class="container">
        <MobileHeaderBack ></MobileHeaderBack>
        <div v-if="isMobile"><img src="../../../static/images/signup_bg_webapp_1.png"></div>
        <div class="signup-wrapper">
            <SignupThemeInfo v-if="!isPopUp"></SignupThemeInfo>
            <div v-if="!referral.isShow" class="signup-form__wrapper">
                <div class="w-100">
                    <h1 v-if="isMobile" class="signup-form__title">{{$t('WELCOME')}}</h1>
                    <h2 v-else class="signup-form__title">{{$t('SIGNUP')}}</h2>
                
                    <!-- phone number -->
                    <div class="form-group">
                        <label class="form-label">{{$t('PHONE_NUMBER')}}</label>
                        <div class="display-flex relative">
                            <div class="display-flex-center signup-form__phone-prefix"  @click="handleDropdown($event)">
                                <span class="mr-1rem">+{{selectedCountry.callingCode}}</span>
                                <img src="../../../static/images/icons/icon_arrow_down_white.png">
                            </div>
                            <Dropdown ref="dropdownRef" :data="countryList" @dropDownChange="dropDownChange($event)"></Dropdown>
                            <input class="form-control"  pattern="[0-9]*" type="text" v-model="phoneNumber.inputValue" v-on:keyup="inputPhoneNumberEvent"  v-on:keyup.enter="postSignupData()" :placeholder="$t('PHONE_NUMBER')">
                        </div>
                        <div class="form-tips">
                            <div v-if="phoneNumber.isError" class="error">{{$t('PHONE_NUMBER_ERROR_MSG')}}</div>
                            <div v-else>(+{{selectedCountry.callingCode}})
                                   <span v-if="selectedCountry.callingCode === 66">098765432</span>
                                    <span v-else>123456789</span>
                            </div>
                        </div>
                    </div>
                    <!-- password -->
                    <!-- <div class="form-group">
                        <label class="form-label">{{$t('PASSWORD')}}</label>
                        <div class="form-group__container">
                            <input class="form-control" :type="showPassword ? 'text' : 'password'" maxlength="20" v-model="password.inputValue" v-on:keyup="inputPasswordEvent" :placeholder="$t('ENTER_PASSWORD')">
                            <div class="display-flex-center form-icon pointer" @click="showPassword = !showPassword">
                                <img v-if="showPassword" src="../../../static/images/icons/icon_close_pwd.png">
                                <img v-else src="../../../static/images/icons/icon_show_pwd.png">
                            </div>
                        </div>
                        <div class="form-tips">
                            <div v-if="password.isError" class="error">{{$t('PASSWORD_ERROR_MSG')}}</div>
                            <div v-else>{{$t('SIGNUP_PASSWORD_TIP')}}</div>
                        </div>
                    </div> -->
                    <!-- confirm password -->
                    <!-- <div class="form-group signup-form__password">
                        <label class="form-label">{{$t('COMFIRM_PASSWORD')}}</label>
                        <div class="form-group__container">
                            <input class="form-control" :type="showConfirmPassword ? 'text' : 'password'"  maxlength="20" v-model="confirmPassword.inputValue" v-on:keyup="inputConfirmPasswordEvent"  :placeholder="$t('COMFIRM_PASSWORD')">
                            <div class="display-flex-center form-icon pointer" @click="showConfirmPassword = !showConfirmPassword">
                                <img v-if="showConfirmPassword" src="../../../static/images/icons/icon_close_pwd.png">
                                <img v-else src="../../../static/images/icons/icon_show_pwd.png">
                            </div>
                        </div>
                        <div class="form-tips">
                            <div v-if="confirmPassword.isError" class="error">{{$t('PLEASE_RETYPE_PASSWORD')}}</div>
                        </div>                    
                    </div> -->
                    <!-- nick name -->
                    <!-- <div class="form-group">
                        <label class="form-label">{{$t('NICKNAME')}}</label>
                        <div class="form-group__container">
                            <input class="form-control" type="text" v-model="nickName.inputValue" v-on:keyup="inputNickNameEvent" maxlength="15" :placeholder="$t('ENTER_NICKNAME')">
                        </div>
                        <div class="form-tips">
                            <div v-if="nickName.isError" class="error">{{$t('NICKNAME_ERROR_MSG')}}</div>
                            <div v-else>{{$t('SIGNUP_NICKNAME_TIP')}}</div>
                        </div>
                    </div> -->
                    <!-- otp -->
                    <div class="form-group">
                        <label class="form-label">{{$t('OTP')}}</label>
                        <div class="display-flex">
                            <input class="form-control mr-1rem flex-3" type="text" v-model="otp.inputValue" v-on:keyup="inputOTPEvent"  v-on:keyup.enter="postSignupData()"  :placeholder="$t('ENTER_OTP')">
                            <div class="" >
                                <button v-if="otp.isShowSendOTP" class="form-control signup-form__disable" :class="{'signup-form__enable': otp.isEnableSendOtpBtn }" @click="handleGetSignupCode('send')">{{$t('SEND_OTP')}}</button>
                                <button v-else class="form-control signup-form__disable" :class="{'signup-form__enable': otp.isEnableResendOtpBtn }" @click="handleGetSignupCode('resend')">{{$t('RESEND')}}<span v-if="!otp.isEnableResendOtpBtn"> ({{otp.currentResetTime}}s)</span></button>
                            </div>
                        </div>
                        <div class="form-tips">
                            <div v-if="!otp.isShowSendOTP && !otp.isError">{{$t('OTP_TIP')}}</div>
                            <div v-if="otp.isError" class="error">{{$t('OTP_ERROR_MSG')}}</div>
                        </div>
                        <div class="signup-form__recaptcha">
                            <vue-recaptcha class="recaptcha-bg-transparent"  ref="invisibleRecaptcha" data-expired-callback="recaptchaExpired"   @expired="onExpiredRecaptcha" @verify="onVerifyRecaptcha" :sitekey="recaptcha.siteKey"></vue-recaptcha>
                        </div>
                    </div>
                    <div class="form-signup__btn-wrapper">
                        <div class="mb-1rem">
                            <button class="form-control signup-form__disable" :class="{'signup-form__enable': isSignUpBtnEnable }" @click="postSignupData()">{{$t('SIGNUP')}}</button>
                        </div>
                        <div class="display-flex-center mb-1rem">
                            <span class="signup-form__seperator-line"></span>
                            <span class="signup-form__seperator-or">{{$t('OR')}}</span>
                            <span class="signup-form__seperator-line"></span>
                        </div>
                        <div class="mb-1rem">
                            <div v-if="isPopUp" @click="handlePopUpSignUp('signIn')"><button class="form-control btn-outline pointer" :class="{'signup-form__enable': isSignInBtnEnable }" >{{$t('LOGIN')}}</button></div>
                            <router-link v-else :to="{ name: 'signin' , query: { callBackUrl: callBackUrl }}"><button class="form-control btn-outline pointer" :class="{'signup-form__enable': isSignUpBtnEnable }">{{$t('LOGIN')}}</button></router-link>
                        </div>
                        <!-- <div class="display-flex-center mb-1rem">
                            <span class="signup-form__seperator-line"></span>
                            <span class="signup-form__seperator-or">{{$t('OR')}}</span>
                            <span class="signup-form__seperator-line"></span>
                        </div>
                        <div class="mb-05rem">
                        <button class="form-control pointer btn-line mb-05rem display-flex-center" @click="signInWithLINE()">
                                <div class="mr-05rem btn-icon"><img class="w-100 h-100" src="../../../static/images/icons/social_media/icon_line_colored.png"></div>
                                <span>{{$t('CONTINUE_WITH_LINE')}}</span>
                            </button>
                            <button class="form-control pointer btn-zalo  display-flex-center" @click="signInWithZalo()">
                                <div class="mr-05rem btn-icon">
                                    <img class="w-100 h-100" src="../../../static/images/icons/social_media/icon_zalo_colored.png">
                                </div>
                                <span>{{$t('CONTINUE_WITH_ZALO')}}</span>
                            </button>
                        </div> -->
                        <!-- <div class="tc font-m td-underline">
                            <router-link :to="{ name: 'signin'}">{{$t('HAVE_AN_ACCOUNT_LOGIN_IN')}}</router-link>
                        </div> -->
                    </div>
                    
                </div>
            </div>
            <!-- referral -->
            <div v-else class="signup-form__wrapper">
                <div class="w-100">
                    <h2 class="signup-form__title">{{$t('ENTER_REFERRAL_CODE')}}</h2>
                
                    <!-- referral code -->
                    <div class="form-group">
                        <label class="form-label">{{$t('REFERRAL_CODE_SIGN_IN')}}</label>
                        <div class="form-group__container">
                            <input class="form-control" v-model="referral.inputReferralCode" v-on:keyup="inputReferralCodeEvent" v-on:paste="inputReferralCodePasteEvent" :placeholder="$t('ENTER_CODE')">
                        </div>
                    </div>
                    <div class="form-signup__btn-wrapper mt-15rem">
                        <div class="mb-1rem">
                            <button class="form-control signup-form__disable" :class="{'signup-form__enable': referral.isNextBtnEnable }" @click="postSignupReferralData()">{{$t('NEXT')}}</button>
                        </div>
                        <div class="tc font-m td-underline">
                            <span class="pointer" @click="handleReferralSkip()">{{$t('SKIP')}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isMobile"><img src="../../../static/images/signup_bg_webapp_2.png"></div>
    </div>
</template>

<script>
const SIGNUP_METHOD = 'PHONE';

import SignupThemeInfo from '@/components/user/SignupThemeInfo.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import Footer from '@/components/footer/Footer.vue'
import MobileHeaderBack from '@/components/mobile/HeaderBack.vue'
import signInZalo from '@/js/user/signInZalo.js'
import { VueRecaptcha } from 'vue-recaptcha';

import config from '@/js/config.js'
import {mapGetters,mapActions} from 'vuex'
export default {
    props: {
        isPopUp : {
            type: Boolean,
            default: false
        }
    },
    components: {
        SignupThemeInfo,
        Dropdown,
        MobileHeaderBack,
        Footer,
        VueRecaptcha
    },
    data() {
        return {
            showPassword : false,
            showConfirmPassword: false,

            countryList: config.countryList,

            selectedCountry: {
                callingCode:''
            },


            isSignUpBtnEnable: false,

            phoneNumber: {
                inputValue: '',
                isError: false
            },

            password: {
                inputValue: '',
                isError: false
            },

            confirmPassword: {
                inputValue: '',
                isError: false
            },

            nickName: {
                inputValue: '',
                isError: false
            },

            otp: {
                inputValue: '',
                isError: false,
                isEnableResendOtpBtn: false,

                isShowSendOTP: true,
                isEnableSendOtpBtn: false,

                currentResetTime: null,
                resetDuration: 120    
            },
            
            referral: {
                isShow: false,
                isNextBtnEnable: false,
                inputReferralCode: '',
            },
            callBackUrl: "",
            recaptcha: {
                value:'',
                siteKey: config.recaptchaSiteKey
            }
        }
    },
    computed: {
        ...mapGetters([
            'currentLocale',
            'isMobile'
        ]),
    },
    //  created() {
    //       window.addEventListener('keydown', this.listenKeypressEvent);
    // },
    //  beforeUnmount() {
    //    window.removeEventListener('keydown', this.listenKeypressEvent)
    // },
    mounted() {
         this.init();
         this.getCurrentLocationData();
    },
    methods: {
         ...mapActions([
            "getWSUP",
            "setUserData",
            "getCurrentLocation",
            "postSignup",
            "postUpdateReferralCode",
            "getLoginMethod",
            "popUpSignInModuleEvent",
            "getContentList",
            "navigationMenuEvent",
            "toastrChangeEvent",
            "currentLocaleChangeEvent"
        ]),
        init() {
            this.syncCallbackUrl();
        },

        syncCallbackUrl(){
            let routeCallBackUrl = this.$route.query.callBackUrl;
            this.callBackUrl = routeCallBackUrl;  
        },
        async inputPhoneNumberEvent() {
           this.phoneNumber.inputValue = this.$tools.onlyNumber( this.phoneNumber.inputValue);
            let minLength = 6;
            let maxLength = 10;

            if (this.phoneNumber.inputValue.length > maxLength) {
                this.phoneNumber.inputValue = this.phoneNumber.inputValue.slice(0, maxLength)
            }

            if (this.phoneNumber.inputValue.length >= minLength && this.phoneNumber.inputValue.length <= maxLength ) {

                this.phoneNumber.isError = false;
                if (this.recaptcha.value !== '') {
                    this.otp.isEnableSendOtpBtn = true;
                    this.otp.isEnableResendOtpBtn = true;
                } else {
                    this.otp.isEnableResendOtpBtn = false;
                }
                // this.otp.isEnableResendOtpBtn = true;
            } else {
                this.phoneNumber.isError = true;

                this.otp.isEnableSendOtpBtn = false;
                // this.otp.isEnableResendOtpBtn = false;
            }
            this.verifyAllInput();
        },

        inputPasswordEvent() {
            let minLength = 8;
            let maxLength = 20;

            if (this.$tools.noSymbolVerification(this.password.inputValue) && this.password.inputValue.length >= minLength && this.password.inputValue.length <= maxLength) {
                this.password.isError = false;
            } else {
                this.password.isError = true;
            }

            this.verifyAllInput();
        },

        inputConfirmPasswordEvent() {
            if (this.password.inputValue !== this.confirmPassword.inputValue ) {
                this.confirmPassword.isError = true;
            } else {
                this.confirmPassword.isError = false;
            }

            this.verifyAllInput();
        },

        inputNickNameEvent() {
            let minLength = 4;
            let maxLength = 15;

            if (this.$tools.noSymbolVerification(this.nickName.inputValue) && this.nickName.inputValue.length >= minLength && this.nickName.inputValue.length <= maxLength) {
                
                this.nickName.isError = false;
            } else {
                this.nickName.isError = true;
            }

            if (this.$tools.noNumberVerification(this.nickName.inputValue)) {
                this.nickName.isError = true;
            }
            this.verifyAllInput();
        },

        inputOTPEvent() {
            this.otp.isError = false;
            this.verifyAllInput();
        },

        verifyAllInput() {
            if (this.phoneNumber.inputValue.length > 0  && this.otp.inputValue.length >0) {
                if (this.phoneNumber.isError) {
                    this.isSignUpBtnEnable = false;
                } else {
                    this.isSignUpBtnEnable = true;
                }
            } else {
                this.isSignUpBtnEnable = false;
            }
            
        },

        handleDropdown(e){
             this.$refs.dropdownRef.handleOpen(e);              
        },
        dropDownChange(e){
            this.selectedCountry = e;
        },

        async getCurrentLocationData() {
            let matchSelectedCountry = false;
            let timeZone = this.$tools.getCurrentTimeZone();

            // detect current location and selected current location country calling code
            this.countryList.forEach(x=> {
                if (x.timeZone === timeZone) {
                    this.$refs.dropdownRef.handleDropdownSelect(x);
                        matchSelectedCountry = true;
                    return false;
                }
            })

            //otherwise default to thailand calling code
            if (!matchSelectedCountry) {
                this.$refs.dropdownRef.handleDropdownSelect(this.countryList[0]);
            }
        },
        handlePopUpSignUp(type) { 
            this.popUpSignInModuleEvent(type)   
            //this.$emit('switchPageEvent', type);
        },
        onVerifyRecaptcha(response) {
            this.recaptcha.value = response;
            this.inputPhoneNumberEvent();
        },
        onExpiredRecaptcha(response) {
            debugger;
            this.recaptcha.value = ""
            this.inputPhoneNumberEvent();
        },
         async handleGetSignupCode(type){
            
            if (type === 'send') {
                this.otp.isShowSendOTP = false;
            } 
  
            this.otp.currentResetTime = this.otp.resetDuration;
            this.otp.isEnableResendOtpBtn = false;
        
            let interval = setInterval(() => {
                if (this.otp.currentResetTime > 0) {// only positive number 
                    this.otp.currentResetTime--;
                }
                
                //enable button when reset is 0
                if (this.otp.currentResetTime === 0) {
                    this.$refs.invisibleRecaptcha.reset();
                    this.recaptcha.value = "";
                    clearInterval(interval);
                }
            }, 1000);
            
            let params = {
                method: SIGNUP_METHOD, //now we only have phone signup. method, i.e.: PHONE, EMAIL
                username:this.selectedCountry.callingCode + this.phoneNumber.inputValue,
                "g-recaptcha-response": this.recaptcha.value
            }
            
            const result = await this.getWSUP(params);

            if (result.result.businessCode === 0) {

            } else {
                let params={
                    isOpen:true,
                    msg:result.result.message,
                    type:'info'       
                }
                this.toastrChangeEvent(params);

                // this.$root.toastrMsg = result.result.message;
                // this.$root.toastrType = 'info';
                // this.$root.$refs.toastrRef.handleOpen(); 
            }
        },

        async postSignupData(){
            if (!this.isSignUpBtnEnable) {
                return
            }
           
            let params = {
                username: this.selectedCountry.callingCode + this.phoneNumber.inputValue,
                // password: this.password.inputValue,
                // confirmPassword: this.confirmPassword.inputValue,
             	code: this.otp.inputValue,
                // displayName: this.nickName.inputValue,
                method: SIGNUP_METHOD,
                preferredLanguage: this.currentLocale === 'vn' ? 'vi' : this.currentLocale
            }
           
            const result = await this.postSignup(params);
            
            if (result.result.businessCode === 0) {
                if (typeof this.$route.query.code !== 'undefined') {
                    this.referral.inputReferralCode = this.$route.query.code;
                } else {
                    this.referral.inputReferralCode = "";
                }
                this.inputReferralCodeEvent();
                this.referral.isShow = true;
                               
                this.setUserData(result.result.data);

                this.signUpSucuessData = result.result;  
            
            } else if (result.result.businessCode === 100025) {
                this.otp.isError = true;
            } else {
                let params={
                    isOpen:true,
                    msg:result.result.message,
                    type:'info'       
                }
                this.toastrChangeEvent(params)
                // this.$root.toastrMsg = result.result.message;
                // this.$root.toastrType = 'info';
                // this.$root.$refs.toastrRef.handleOpen(); 
            }
            
        },

        async getContentListData() {
            let params = {
                timeType: 2,
                language: this.currentLocale
            }
         
            const result = await this.getContentList(params);

            let returnResult = result.result;

            this.navigationMenuEvent(returnResult);
        },

        getLineToken() {
            let length = 32;
            let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for ( let i = 0; i < length; i++ ) {
                result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
            }
            return btoa(result);
        },
        // listenKeypressEvent(e) {
        //      if (e.key === "Enter") {
        //        this.postSignupData();
        //     } 
        // },
         signInWithLINE() {
            let channelId = config.lineChannelId;
            let state = this.getLineToken();
            let nonce = this.getLineToken();
            let scope = "openid%20profile";

            localStorage.removeItem("state");
            localStorage.removeItem("nonce");
            localStorage.removeItem("currentLocale");

            let callbackUrl = window.location.origin + "/lineCallback";

            let encodedCallbackUrl = encodeURIComponent(callbackUrl);

            localStorage.setItem("state", state);
            localStorage.setItem("nonce", nonce);
            localStorage.setItem("currentLocale", this.currentLocale);
           
            let url = "https://access.line.me/oauth2/v2.1/authorize?response_type=code"
                        + "&client_id=" + channelId
                        + "&redirect_uri=" + encodedCallbackUrl
                        + "&state=" + state
                        + "&scope=" + scope
                        + "&nonce=" + nonce;
          
            let new_window = this.$tools.popupwindow(url,'', 550, 570);
        },
        signInWithZalo() {
            localStorage.removeItem("currentLocale");
            localStorage.setItem("currentLocale", this.currentLocale);
            
            signInZalo.redirectToZaloLoginPage();
        },

        handleReferralSkip() {
             let locale = `${this.signUpSucuessData.data.preferredLanguage === 'vi' ? 'vn' : this.signUpSucuessData.data.preferredLanguage}`;
             if (this.isPopUp) {

                this.$emit('loginSucuessEvent','signUp');
                this.currentLocaleChangeEvent(locale);
                let params= {
                    isOpen:true,
                    msg:'WELCOME_TO_SPORTS168',     
                }
                this.toastrChangeEvent(params)
                //this.$root.toastrMsg ='WELCOME_TO_SPORTS168';
                //this.$root.$refs.toastrRef.handleOpen();
             } else {
               

                let callBackUrl = "";
                let callBackUrlArray = [];
                let addRedirectWord = "";

                let routeCallBackUrl = this.$route.query.callBackUrl;
                if (routeCallBackUrl) {
                    //always use the locale that come from user info api
                    callBackUrlArray = routeCallBackUrl.split('/');
                    callBackUrlArray[1] = locale;
                    callBackUrl =  callBackUrlArray.join('/');

                    if (callBackUrl.includes('?')) {
                        addRedirectWord = "&";
                    } else {
                        addRedirectWord = "?";
                    }
                    callBackUrl = callBackUrl + `${addRedirectWord}redirectFrom=signUp`;   
                }
            
                if (callBackUrl === "") {
                    this.$router.push(`/${locale}`); 
                }else {
                    this.$router.push(`${callBackUrl}`); 
                }

                if (routeCallBackUrl !== '') {
                    if (callBackUrlArray[2] !== 'event') {
                            setTimeout(() => {
                            let params={
                                isOpen:true,
                                msg:'WELCOME_TO_SPORTS168',     
                            }
                            this.toastrChangeEvent(params)
                            // this.$root.toastrMsg ='WELCOME_TO_SPORTS168';
                            // this.$root.$refs.toastrRef.handleOpen(); 
                        }, 1000);
                    }                      
                }
             }
         
        },

        inputReferralCodePasteEvent() {
            //temporary solution for paste event handle on ios
            setTimeout(() => {
                this.inputReferralCodeEvent();
            }, 300);
        },

        inputReferralCodeEvent() {        
            if (this.referral.inputReferralCode !== '') {
                this.referral.isNextBtnEnable = true;
            } else {
                this.referral.isNextBtnEnable = false;
            }
        },

        async postSignupReferralData() {
            let params = {
                referralCode: this.referral.inputReferralCode
            }

            const result = await this.postUpdateReferralCode(params);

            if (result.result.businessCode === 0) {
                this.handleReferralSkip();
            } else {
                
                let params={
                    isOpen:true,
                    msg:result.result.message,
                    type:'info'       
                }
                this.toastrChangeEvent(params)
                // this.$root.toastrMsg = result.result.message;
                // this.$root.toastrType = 'info';
                // this.$root.$refs.toastrRef.handleOpen(); 
            }
        }

    }
}
</script>

<style scoped>
 
</style>